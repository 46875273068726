import { FirebaseAuthProvider } from "react-admin-firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDmUskGejxZb5mMOtFJc4MPWLMJYxA9JEc",
  authDomain: "valentynaorekhova-4bdbe.firebaseapp.com",
  projectId: "valentynaorekhova-4bdbe",
  storageBucket: "valentynaorekhova-4bdbe.appspot.com",
  messagingSenderId: "1042878378548",
  appId: "1:1042878378548:web:4918ca0be65ccffdbf9061",
  measurementId: "G-FHBVXTBD5K"
};

const authProvider = FirebaseAuthProvider(firebaseConfig);

export default authProvider;
