import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton
} from "react-admin";

export default function Pages(props) {
  return (
    <List {...props} title="Pages">
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="slug" />
        <EditButton />
      </Datagrid>
    </List>
  );
}
