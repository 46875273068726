import {
  RichTextField,
  ColorField,
  FormTextField,
  EntityReferenceField
} from "./fields";
import { gql } from "graphql-request";

export default function GalleryFields(props) {
  return (
    <>
      <EntityReferenceField
        name="gallery"
        label="Select Gallery"
        query={gql`
          query LoadEntities {
            options: photostudio_galleries {
              id
              name
            }
          }
        `}
      />
      <FormTextField name="title" label="Title" />
      <RichTextField
        name="headline"
        label="Headline"
        placeholder="Headline"
        multiline
        rows={8}
      />
      <ColorField name="color" label="Text Color" />
    </>
  );
}
