import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ImageInput,
  ImageField,
  ReferenceInput,
  SelectInput
} from "react-admin";
import * as React from "react";
import ShootUrl from "./ShootUrl";

export default function ShootsEdit(props) {
  console.log("props:", props);
  return (
    <Edit {...props} title="Shoot">
      <SimpleForm>
        <ShootUrl />
        <ReferenceInput
          label="Customer"
          source="customer_id"
          reference="photostudio.customers"
          perPage={200}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="title" />
        <TextInput source="description" />
        <TextInput source="slug" />
        <TextInput source="view_password" />
        <ImageInput multiple source="images" src="image" title="name">
          <ImageField source="image" title="name" />
        </ImageInput>
        <DateInput source="updated_at" />
        <DateInput source="created_at" />
      </SimpleForm>
    </Edit>
  );
}
