import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useState } from "react";
import { ItemTypes } from "./ItemTypes";

export default function ComponentSelector({ onSelect }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const select = event => {
    onSelect(event.target.dataset.id);
    handleClose();
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Add Component
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem data-id={ItemTypes.HERO} onClick={select}>
          Hero
        </MenuItem>
        <MenuItem data-id={ItemTypes.IMAGE_WITH_TEXT} onClick={select}>
          Image With Text
        </MenuItem>
        <MenuItem data-id={ItemTypes.NEWSLETTER} onClick={select}>
          Newsletter Signup
        </MenuItem>
        <MenuItem data-id={ItemTypes.GALLERY} onClick={select}>
          Image Gallery
        </MenuItem>
        <MenuItem data-id={ItemTypes.TEXT} onClick={select}>
          Text
        </MenuItem>
      </Menu>
    </div>
  );
}
