import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import IconButton from "@material-ui/core/IconButton";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import PropsEditor from "./propsEditor";

const style = {
  border: "1px dashed gray",
  padding: "0.5rem 1rem",
  marginBottom: ".5rem",
  backgroundColor: "white"
};
const handleStyle = {
  width: "2rem",
  height: "2rem",
  display: "inline-block",
  marginRight: "0.75rem",
  textAlign: "center",
  cursor: "move"
};

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: "left",
    color: theme.palette.text.primary,
    display: "flex",
    cursor: "pointer",
    alignItems: "center"
  },
  handle: {
    textAlign: "left",
    display: "flex",
    cursor: "hand",
    alignItems: "center",
    "& div": {
      marginTop: "0.4em"
    }
  },
  actions: {
    textAlign: "right"
  }
}));

export const Component = ({
  id,
  type,
  props,
  index,
  moveCard,
  removeCard,
  updateComponent
}) => {
  const ref = useRef(null);
  const [isEditingProps, setEditingProps] = useState(false);
  const classes = useStyles();

  const [, drop] = useDrop({
    accept: "component",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ opacity }, drag, preview] = useDrag({
    item: { type: "component", id, index },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });

  drag(drop(ref));

  const editProps = () => {
    setEditingProps(true);
  };

  const text = getComponentTitle(type);

  return (
    <div ref={preview} style={{ ...style, opacity }} onDoubleClick={editProps}>
      <Grid container>
        <Grid item xs={1} className={classes.handle}>
          <div ref={ref} style={handleStyle}>
            <DragHandleIcon />
          </div>
        </Grid>
        <Grid item xs={9} className={classes.title}>
          {text}
        </Grid>

        <Grid item xs={1} className={classes.actions}>
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={e => removeCard(index)}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      {isEditingProps && (
        <PropsEditor
          type={type}
          props={props}
          onClose={e => setEditingProps(false)}
          onSave={values => updateComponent(index, values)}
        />
      )}
    </div>
  );
};

function getComponentTitle(type) {
  switch (type) {
    case ItemTypes.HERO:
      return "Hero";
    case ItemTypes.NEWSLETTER:
      return "Newsletter";
    case ItemTypes.IMAGE_WITH_TEXT:
      return "Image w/ text";
    case ItemTypes.TEXT:
      return "Text";
    case "hero":
      return "Hero";
    case "gallery":
      return "Image Gallery";
    default:
      return "Unknown type";
  }
}
