import { Field } from "react-final-form";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Container } from "./Container";

function ContentBlockComponent(props) {
  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <Container {...props.input} />
      </DndProvider>
    </div>
  );
}

export function ContentBlock(props) {
  const { source } = props;
  return (
    <span>
      <Field name={source} component={ContentBlockComponent} />
    </span>
  );
}
