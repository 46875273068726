import { Field } from "react-final-form";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { default as client } from "../../../hasuraClient";
import { useState, useEffect } from "react";

export default function EntityReferenceField(props) {
  const { name, label, query, variables = {} } = props;
  const [list, setList] = useState([]);

  const preloadEntityList = async () => {
    const result = await client.request(query, variables);
    setList(result.options);
  };

  useEffect(() => {
    preloadEntityList();
  });

  if (!list.length) {
    return <div>Loading entities..</div>;
  }

  return (
    <Field name={name}>
      {props => (
        <FormControl fullWidth={true}>
          {console.log("selected:", props.input.value)}
          <Autocomplete
            id={name + "-ref"}
            options={list}
            getOptionLabel={option => {
              if (option.name) {
                return option.name;
              }

              const selection = list.find(item => item.id === option);
              if (selection) {
                return selection.name;
              }

              return "Select...";
            }}
            style={{ width: 300 }}
            renderInput={params => (
              <TextField {...params} label={label} variant="outlined" />
            )}
            getOptionSelected={option => {
              return option.id === props.input.value;
            }}
            onChange={(event, newValue) => {
              props.input.onChange(newValue.id);
            }}
            value={props.input.value}
          />
        </FormControl>
      )}
    </Field>
  );
}
