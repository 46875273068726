import {
  Create,
  SimpleForm,
  TextInput,
  DateInput
} from "react-admin";
export default function PageCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" />
        <TextInput source="slug" />
        <DateInput source="updated_at" />
        <DateInput source="created_at" />
      </SimpleForm>
    </Create>
  );
}
