import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput
} from "react-admin";
import { ContentBlock } from "../cmsBlocks/ContentBlock";
export default function PageEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="src" />
        <DateInput source="updated_at" />
        <DateInput source="created_at" />
        <ContentBlock source="content" />
      </SimpleForm>
    </Edit>
  );
}
