import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput
} from "react-admin";

export default function CustomerEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="email" />
        <SelectInput
          source="language"
          choices={[
            { id: "en", name: "English" },
            { id: "sk", name: "Slovak" },
          ]}
        />
        <DateInput source="updated_at" />
        <DateInput source="created_at" />
      </SimpleForm>
    </Edit>
  );
}
