import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  DateInput, ImageInput
} from "react-admin";
export default function GalleryCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="slug" />
        <ImageInput multiple source="images" />
        <BooleanInput source="published" />
        <DateInput source="updated_at" />
        <DateInput source="created_at" />
      </SimpleForm>
    </Create>
  );
}
