import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton
} from "react-admin";

export default function Galleries(props) {
  return (
    <List {...props} title="Galleries">
      <Datagrid rowClick="edit">
        <TextField source="id" />
        {/*<ReferenceField source="userId" reference="users">*/}
        {/*  <TextField source="name" />*/}
        {/*</ReferenceField>*/}
        <TextField source="name" />
        <BooleanField source="published" />
        <EditButton />
      </Datagrid>
    </List>
  );
}
