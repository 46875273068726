import {
  Create,
  SimpleForm,
  TextInput,
  DateInput, SelectInput
} from "react-admin";

export default function CustomerCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="email" />
        <SelectInput
            source="language"
            choices={[
              { id: "en", name: "English" },
              { id: "sk", name: "Slovak" },
            ]}
        />
        <DateInput source="updated_at" />
        <DateInput source="created_at" />
      </SimpleForm>
    </Create>
  );
}
