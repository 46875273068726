import React  from "react";
import { Form } from "react-final-form";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ItemTypes } from "../ItemTypes";
import TextFields from "./TextFields";
import ImageWithTextFields from "./ImageWithTextFields";
import HeroFields from "./HeroFields";
import GalleryFields from "./GalleryFields";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

// Mapped editors
const editors = {
  [ItemTypes.TEXT]: TextFields,
  [ItemTypes.HERO]: HeroFields,
  [ItemTypes.IMAGE_WITH_TEXT]: ImageWithTextFields,
  [ItemTypes.GALLERY]: GalleryFields
};

export default function PropsEditor({ type, props, onClose, onSave }) {
  const onSubmitProps = values => {
    onSave(values);
    onClose();
    return false;
  };

  let FieldEditor = editors[type];
  if (!FieldEditor) {
    FieldEditor = () => (
      <div>
        No fields found for component type: <b>{type}</b>
      </div>
    );
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={true}
      maxWidth={"lg"}
    >
      <Form
        onSubmit={onSubmitProps}
        initialValues={props}
        render={({ handleSubmit }) => (
          <>
            <DialogTitle id="customized-dialog-title" onClose={onClose}>
              {type}
            </DialogTitle>
            <DialogContent dividers>
              <FieldEditor />
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleSubmit} color="primary">
                Save changes
              </Button>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  );
}
