import { GraphQLClient } from "graphql-request";

export const headers = {
  "content-type": "application/json",
  "x-hasura-admin-secret": process.env.REACT_APP_HASURA_SECRET
};

export const HASURA_HOST = process.env.REACT_APP_HASURA_HOST;

const client = new GraphQLClient(
  `https://${process.env.REACT_APP_HASURA_HOST}/v1/graphql`,
  {
    headers
  }
);

export default client;
