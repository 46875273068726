import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { makeStyles } from "@material-ui/core/styles";
import AssetManagementContent from "./AssetManagementContext";
import Button from "@material-ui/core/Button";
import { useContext } from "react";
import cdnUrl from "../../../../cdnUrl";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    width: "100%",
    height: "100%"
  }
}));

export default function AssetList(props) {
  const classes = useStyles();
  const { files } = props;

  return (
    <div className={classes.root}>
      <GridList cellHeight={160} className={classes.gridList} cols={3}>
        {files.map(file => (
          <GridListTile key={file.name} cols={1} rows={1}>
            <AssetItem file={file} />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}

function AssetItem({ file }) {
  const context = useContext(AssetManagementContent);
  const { name } = file;

  if (name.includes(".jpg") || name.includes(".png")) {
    const src = cdnUrl(file.src);
    return (
      <Button onClick={e => context.selectAsset(file)}>
        <img src={src} alt={file.name} />
      </Button>
    );
  } else {
    return (
      <Button onClick={e => context.selectAsset(file)}>{file.name}</Button>
    );
  }
}
