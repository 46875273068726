import { RichTextField, ColorField } from "./fields";

export default function TextFields(props) {
  return (
    <>
      <RichTextField
        name="text"
        label="Text"
        placeholder="First Name"
        multiline
        rows={10}
      />
      <ColorField name="color" label="Text Color" />
    </>
  );
}
