import { gql } from "graphql-request";
import { default as client } from "./hasuraClient";

export default async function handleShoots(
  dataProvider,
  type,
  resource,
  params
) {
  if (!params?.data?.images) {
    const result = await dataProvider(type, resource, params);

    if (type === "GET_ONE") {
      result.data.images = await loadImages(loadGalleryImages, result.data.id);
    }

    return result;
  }

  const newPictures = params.data.images.filter(p => p.rawFile instanceof File);

  const formerPictures = params.data.images.filter(
    p => !(p.rawFile instanceof File)
  );

  const newData = params.data;
  delete newData.images;

  const result = await dataProvider(type, resource, {
    ...params,
    data: newData
  });

  try {
    await uploadImages(result, formerPictures, newPictures);
  } catch(e) {
    console.error(e)
    throw e
  }

  return result;
}

const updateGalleryImages = gql`
  mutation UpdateImages(
    $oldImages: [bigint!]!
    $images: [photostudio_shoot_image_insert_input!]!
  ) {
    delete_photostudio_shoot_image(
      where: { _not: { id: { _in: $oldImages } } }
    ) {
      affected_rows
    }

    insert_photostudio_shoot_image(objects: $images) {
      affected_rows
      returning {
        id
        image
      }
    }
  }
`;

const updateGalleryImagesNoDelete = gql`
  mutation UpdateImages(
    $images: [photostudio_shoot_image_insert_input!]!
  ) {
    insert_photostudio_shoot_image(objects: $images) {
      affected_rows
      returning {
        id
        image
      }
    }
  }
`;

const loadGalleryImages = gql`
  query LoadImages($id: bigint) {
    data: photostudio_shoot_image(where: { shoot_id: { _eq: $id } }) {
      id
      name
      image
    }
  }
`;

async function uploadImages(result, formerPictures, images) {
  const {
    data: { id }
  } = result;

  const urls = [];
  for (const image of images) {
    const { rawFile, name } = image;

    const response = await fetch(`/api/upload?name=${name}`);
    const uploadUrl = await response.text();

    const uploadResult = await fetch(uploadUrl, {
      method: "PUT",
      body: rawFile
    });

    await uploadResult.text();

    urls.push({
      shoot_id: id,
      image: process.env.REACT_APP_ASSET_S3_BUCKET + `/${name}`,
      name: name
    });
  }

  if(formerPictures.length > 0) {
    await client.request(updateGalleryImages, {
      oldImages: formerPictures.map(item => item.id),
      images: urls
    });
  } else {
    await client.request(updateGalleryImagesNoDelete, {
      images: urls
    });

  }
}

async function loadImages(query, id) {
  const result = await client.request(query, {
    id
  });

  return result.data.map(({ id, name, image }) => ({
    id,
    image:
      `https://${
        process.env.REACT_APP_IMGPROXY_DOMAIN
      }/plain/rs:fill:320:240/` + btoa("s3://" + image),
    name
  }));
}
