import { Field } from "react-final-form";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { makeStyles } from "@material-ui/core/styles";

export { default as RichTextField } from "./RichText";
export { default as ImageField } from "./ImageField";
export { default as EntityReferenceField } from "./EntityReferenceField";

export function FormTextField(props) {
  const { name, label, placeholder, multiline, rows } = props;

  return (
    <Field name={name}>
      {props => (
        <TextField
          label={label}
          placeholder={placeholder}
          multiline={multiline}
          rows={rows}
          {...props.input}
        >
          {props.input.value}
        </TextField>
      )}
    </Field>
  );
}

const themeColors = {
  text: "#000",
  background: "#fff",
  primary: "#ed0f00",
  primaryText: "#fff",
  secondary: "#00a",
  gray: "#eee",
  grayText: "#333333",
  lightgray: "#fafafa",
  link: "#006bb4",
  error: "red",
  success: "#e5efe5",
  successText: "#006400",
  info: "#fdf0d5",
  infoText: "#6f4400",
  buttonPrimary: "#f5a623",
  buttonPrimaryText: "#fff"
};

const useColorFieldStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export function ColorField(props) {
  const { name, label } = props;
  const classes = useColorFieldStyles();

  return (
    <Field name={name}>
      {props => (
        <FormControl className={classes.formControl}>
          <InputLabel id={"color-field-" + name}>{label}</InputLabel>
          <Select
            labelId={"color-field-" + name}
            id={"color-select-" + name}
            value={props.input.value}
            onChange={props.input.onChange}
          >
            {Object.entries(themeColors).map(([colorName, value]) => (
              <MenuItem key={colorName} value={colorName}>
                <FiberManualRecordIcon style={{ color: value }} />
                {colorName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Field>
  );
}
