import hasuraProvider from "ra-data-hasura";
import { headers, HASURA_HOST } from "./hasuraClient";
import handleGalleries from "./galleriesSource";
import handleShoots from "./shootsSource";
import authProvider from "./authProvider";
import lookupUser from "./lookupUser";

const dataProvider = hasuraProvider("https://" + HASURA_HOST, headers);

const myDataProvider = async (type, resource, params) => {
  const user = await authProvider.getAuthUser();
  const dbUser = await lookupUser(user);

  if (!user || !dbUser) {
    throw new Error("Unauthorized");
  }

  if (resource === "photostudio.galleries") {
    return handleGalleries(dataProvider, type, resource, params);
  }

  if (resource === "photostudio.shoots") {
    return handleShoots(dataProvider, type, resource, params);
  }

  return dataProvider(type, resource, params);
};

export default myDataProvider;
