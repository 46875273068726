import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}));

export default function ShootUrl(props) {
  const classes = useStyles();

  const { record } = props;
  const baseURL = process.env.REACT_APP_WEBSITE_URL;
  const url = `${baseURL}/shoots/${record.slug}`;

  return (
    <Paper className={classes.root}>
      <h3>Gallery Link (send to client):</h3>
      <a href={url} target="_blank" rel="noreferrer">
        {url}
      </a>
    </Paper>
  );
}
