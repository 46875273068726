import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  DateInput,
  ImageInput,
  ImageField
} from "react-admin";

export default function GalleryEdit(props) {
  console.log("props", props);
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="slug"a />
        <ImageInput multiple source="images" src="image" title="name">
          <ImageField source="image" title="name" />
        </ImageInput>
        <BooleanInput source="published" />
        <DateInput source="updated_at" />
        <DateInput source="created_at" />
      </SimpleForm>
    </Edit>
  );
}
