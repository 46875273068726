import hasuraProvider from "./dataProvider";
import { Admin, Resource } from "react-admin";
import { Galleries, GalleriesEdit, GalleriesCreate } from "./galleries";
import { Customers, CustomersEdit, CustomersCreate } from "./customers";
import { Shoots, ShootsEdit, ShootsCreate } from "./shoots";
import { Photos, PhotosCreate } from "./photos";
import { Pages, PagesCreate, PagesEdit } from "./pages";
import { Assets, AssetsEdit } from "./assets";
import PeopleIcon from "@material-ui/icons/People";
import FolderIcon from "@material-ui/icons/Folder";
import ImageIcon from "@material-ui/icons/Image";
import WebAsset from "@material-ui/icons/WebAsset";
import Camera from "@material-ui/icons/Camera";
import CustomLoginPage from "./CustomLoginPage";
import authProvider from "./authProvider";

const App = () => (
  <Admin
    dataProvider={hasuraProvider}
    loginPage={CustomLoginPage}
    authProvider={authProvider}
  >
    <Resource
      name="photostudio.customers"
      list={Customers}
      create={CustomersCreate}
      edit={CustomersEdit}
      icon={PeopleIcon}
      options={{ label: "Customers" }}
    />
    <Resource
      name="photostudio.galleries"
      list={Galleries}
      create={GalleriesCreate}
      edit={GalleriesEdit}
      icon={FolderIcon}
      options={{ label: "Galleries" }}
    />
    <Resource
      name="photostudio.shoots"
      list={Shoots}
      create={ShootsCreate}
      edit={ShootsEdit}
      icon={Camera}
      options={{ label: "Shoots" }}
    />
    <Resource
      name="photostudio.photos"
      list={Photos}
      create={PhotosCreate}
      // edit={GalleriesEdit}
      icon={ImageIcon}
      options={{ label: "Photos" }}
    />
    <Resource
      name="cms.pages"
      list={Pages}
      create={PagesCreate}
      edit={PagesEdit}
      icon={FolderIcon}
      options={{ label: "Pages" }}
    />
    <Resource
      name="cms.assets"
      list={Assets}
      edit={AssetsEdit}
      icon={WebAsset}
      options={{ label: "Assets" }}
    />
  </Admin>
);

export default App;
