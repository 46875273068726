import React, { useState, useCallback, useEffect } from "react";
import { Component } from "./Component";
import update from "immutability-helper";
import ComponentSelector from "./ComponentSelector";
import { ItemTypes } from "./ItemTypes";

const style = {
  width: 400
};

export const Container = props => {
  const { value, onChange } = props;
  const [components, setComponents] = useState(
    () => (value ? JSON.parse(value) : [])
  );

  // update input state
  useEffect(
    () => {
      onChange(JSON.stringify(components));
    },
    [components, onChange]
  );

  const moveComponent = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = components[dragIndex];
      setComponents(
        update(components, {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]]
        })
      );
    },
    [components]
  );

  const removeComponent = useCallback(
    index => {
      setComponents(
        update(components, {
          $splice: [[index, 1]]
        })
      );
    },
    [components]
  );

  const updateComponent = useCallback(
    (index, props) =>
      setComponents(
        update(components, {
          [index]: {
            $merge: {
              props
            }
          }
        })
      ),
    [components]
  );

  const renderComponent = (component, index) => {
    return (
      <Component
        key={component.id}
        index={index}
        id={component.id}
        moveCard={moveComponent}
        removeCard={removeComponent}
        updateComponent={updateComponent}
        type={component.type}
        props={component.props}
      />
    );
  };

  const createComponent = useCallback(
    id => {
      setComponents([
        ...components,
        {
          id: findLastId(components),
          type: id,
          props: getDefaultComponentPropsFor(id)
        }
      ]);
    },
    [components]
  );

  return (
    <>
      <div style={style}>
        {components.map((card, i) => renderComponent(card, i))}
      </div>
      <ComponentSelector onSelect={createComponent} />
    </>
  );
};

function findLastId(cards) {
  const lastId = Math.max.apply(Math, cards.map(card => card.id));
  return lastId > 0 ? lastId + 1 : 1;
}

function getDefaultComponentPropsFor(id) {
  switch (id) {
    case ItemTypes.HERO:
      return {
        image: ""
      };

    case ItemTypes.IMAGE_WITH_TEXT:
      return {
        image: "",
        text: ""
      };

    case ItemTypes.NEWSLETTER:
      return {};

    case ItemTypes.TEXT:
      return {
        text: "text content"
      };

    default:
      return {};
  }
}
