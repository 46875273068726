import { Field } from "react-final-form";
import AssetManager from "./components/AssetManager";

export default function ImageField(props) {
  const { name } = props;

  return (
    <Field name={name}>
      {props => <AssetManager type="image" input={props.input} />}
    </Field>
  );
}
