import { RichTextField, ImageField, ColorField } from "./fields";

export default function HeroFields(props) {
  return (
    <>
      <ImageField name="image" label="Image" />
      <RichTextField
        name="headline"
        label="Headline"
        placeholder="Headline"
        multiline
        rows={8}
      />
      <ColorField name="color" label="Text Color" />
    </>
  );
}
