import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  ImageInput,
  ReferenceInput,
  SelectInput
} from "react-admin";
import * as React from "react";
export default function GalleryCreate(props) {
  return (
    <Create {...props} title="Shoot">
      <SimpleForm>
        <ReferenceInput
          label="Customer"
          source="customer_id"
          reference="photostudio.customers"
          perPage={200}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="title" />
        <TextInput source="description" />
        <TextInput source="view_password" />
        <ImageInput multiple source="images" />
        <DateInput source="updated_at" />
        <DateInput source="created_at" />
      </SimpleForm>
    </Create>
  );
}
