import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import AssetList from "./AssetList";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDropzone } from "react-dropzone";
import { gql } from "graphql-request";
import { default as client } from "../../../../hasuraClient";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AssetManagerDialog(props) {
  const { open, onClose } = props;
  const classes = useStyles();
  const [files, setFiles] = useState([]);

  const reloadList = async () => {
    const list = await loadAssets();
    setFiles(list);
  };

  const onUpload = useCallback(() => {
    reloadList();
  }, []);

  useEffect(() => {
    reloadList();
  }, []);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Asset Manager
          </Typography>
          <MyDropzone onUpload={onUpload} />
        </Toolbar>
      </AppBar>
      <AssetList files={files} />
    </Dialog>
  );
}

const useBackdropStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

function MyDropzone(props) {
  const { onUpload } = props;
  const classes = useBackdropStyles();
  const [isUploading, setUploading] = useState(false);

  const onDrop = useCallback(
    async acceptedFiles => {
      setUploading(true);
      await uploadAssets(acceptedFiles);
      setUploading(false);
      onUpload();
    },
    [setUploading, onUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <Backdrop className={classes.backdrop} open={isUploading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
    </div>
  );
}

const uploadAssetsMutation = gql`
  mutation UploadAssets($files: [cms_assets_insert_input!]!) {
    insert_cms_assets(objects: $files) {
      affected_rows
      returning {
        id
        name
        src
      }
    }
  }
`;

async function uploadAssets(files) {
  const fileList = [];
  for (const file of files) {
    console.log(file.name, file.type);
    const { name } = file;

    const response = await fetch(`/api/upload?name=${name}`);
    const uploadUrl = await response.text();

    const uploadResult = await fetch(uploadUrl, {
      method: "PUT",
      body: file
    });

    await uploadResult.text();

    fileList.push({
      user_id: 1,
      src: process.env.REACT_APP_ASSET_S3_BUCKET + `/${name}`,
      name: name
    });
  }

  await client.request(uploadAssetsMutation, {
    files: fileList
  });
}

const loadAssetsQuery = gql`
  query LoadAssets($userId: bigint!) {
    cms_assets(where: { user_id: { _eq: $userId } }) {
      id
      name
      src
    }
  }
`;

async function loadAssets() {
  const result = await client.request(loadAssetsQuery, {
    userId: 1
  });

  return result.cms_assets;
}
