import { gql } from "graphql-request";
import { default as client } from "./hasuraClient";

const getUser = gql`
  query getUser($email: String!) {
    data: photostudio_users(where: { email: { _eq: $email } }) {
      id
    }
  }
`;

export default async function lookupUser(user) {
  const cached = localStorage.getItem("db_user");
  if (cached) {
    return JSON.parse(cached);
  }

  const result = await client.request(getUser, {
    email: user.email
  });

  if (!result.data || !result.data.length) {
    return null;
  }

  localStorage.setItem("db_user", JSON.stringify(result.data[0]));

  return result.data[0];
}
