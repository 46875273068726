import AssetManagerDialog from "./AssetManagerDialog";
import { useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ImageIcon from "@material-ui/icons/Image";
import AssetManagementContent from "./AssetManagementContext";
import cdnUrl from "../../../../cdnUrl";

export default function AssetManager(props) {
  const { input } = props;
  const [opened, setOpened] = useState(false);

  const closeManager = () => {
    setOpened(false);
  };

  const context = {
    selectAsset: asset => {
      setOpened(false);
      input.onChange(asset.src);
    }
  };

  return (
    <div>
      <InputLabel id="label">Image</InputLabel>
      <AssetPreview src={input.value} />
      <Button
        startIcon={<ImageIcon />}
        onClick={() => setOpened(true)}
        variant="contained"
        color="primary"
      >
        Select Image
      </Button>

      <AssetManagementContent.Provider value={context}>
        <AssetManagerDialog open={opened} onClose={closeManager} />
      </AssetManagementContent.Provider>
    </div>
  );
}

function AssetPreview({ src }) {
  if (src && (src.includes(".jpg") || src.includes(".png"))) {
    const imgSrc = cdnUrl(src);
    return (
      <Paper>
        <img src={imgSrc} alt="a jpg " />
      </Paper>
    );
  }

  return (
    <Paper>
      <ImageIcon />
    </Paper>
  );
}
